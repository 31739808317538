import React, { useState, useEffect } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const Counter = (params) => {


    const getCorrectedBaseValue = (baseValue) => {
        // baseValue= baseValue + rate*( currentTime - 1oct,2024)
        baseValue = baseValue + params.rate * (Date.now() - Date.UTC(2023, 1, 1)) / 1000;
        return baseValue;


    };

    const getCommaFormate = (num) => {
        // return comma separated no like 10000 to 1,000
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const correctBaseValue = getCorrectedBaseValue(params.baseValue);
    const [count, setCount] = useState(correctBaseValue);

    useEffect(() => {
        const interval = setInterval(() => {
            setCount(prevCount => prevCount + params.rate);
        }, 1000); // Increase every second

        return () => clearInterval(interval); // Cleanup on u abhvab anmount
    }, [params.rate]);

    return (

        <Row className="justify-content-md-center" style={{"border": "0.5px solid 0.3"  }} of>
            <Col lg={6} md={6} xs={6} style={{ textAlign: 'right', padding:'3px' }} >{params.title} </Col>
            <Col lg={6} md={6} xs={6} style={{ textAlign: 'left', padding:'3px', paddingLeft:'8px'  }}>
               {getCommaFormate(Math.round(count))}
            </Col>
        </Row>

    );
}

export default Counter;
