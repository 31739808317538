import React from "react";
import Counters from "../components/Counters";
import Header from "../components/Header";
import { Row,Col } from "react-bootstrap";
import IndiaMap from "../components/IndiaMap/IndiaMap"; 
import heatMapData from "../components/IndiaMap/constants/BasicEconomicData";
import { perCapitaIncome } from "../components/IndiaMap/constants/EconomicData";
const RisingIndia = () => {
  return (
    <Col lg={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} xs={{ span: 12, offset: 0 }}>


    <div className="d-flex flex-column min-vh-100">
      {/* Main content area */}
      <div className="flex-grow-1">
        <div className="container">
          <Header />

          <Row style={{"paddingTop":"25px"}} >
            <Col lg={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12, offset: 0 }}>

              <h1 style={{ "textAlign": "center" }}>Rising India</h1>
              <p style={{ "textAlign": "center" }}>
                Witness India's growth rate is increasing at an unprecedented rate !!! </p>
              <Counters />
              
            </Col>
            <Col lg={{ span: 6 }} md={{ span: 12 }} xs={{ span: 12, offset: 0 }}>

              {/* <img
                src="/images/indiaMap.png"
                alt="India Map"
                className="img-fluid"
                // style={{ "border": "0.5px dashed", "borderRadius": "10px" }}
              /> */}
              <h3> 
               <center>Per Capita Income</center> 
              </h3>
              <IndiaMap data={perCapitaIncome} unit={"in rupees"}/>
            </Col>

          </Row>
        </div>
      </div>

      <div className="container text-center">
        <hr style={{ "border": "0.2px solid" }} />
        <p>&copy; RisingIndia.fyi  {new Date().getFullYear()}  | All rights reserved.</p>
      </div>
    </div>

  </Col>
  );
};

export default RisingIndia;