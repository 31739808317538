import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { Row, Col,Table } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "react-data-table-component";

const DropdownComponent = ({ options, handleSelect, selectedOption, tableData, unit }) => {
    const columns = [
        {
            name: "State",
            selector: row => row.state,
            sortable: true,
        },
        {
            // name: <></>`${selectedOption} (${unit})`  ,
            name :<div style={{ wordWrap: "break-word", whiteSpace: "normal" }}> {selectedOption} {unit} </div>,
            selector: row => row.value,
            sortable: true,
            format: row => (row.value !== null ? row.value.toLocaleString() : "NA"),
        },
    ];

    return (
        <Row>
            <Row>
                <Col>
                    <Dropdown onSelect={handleSelect}>
                        <Dropdown.Toggle variant="light" id="dropdown-basic" size="large">
                            Selected: {selectedOption}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {options.map((option, index) => (
                                <Dropdown.Item key={index} eventKey={option}>
                                    {option}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>

            <Row style={{ paddingTop: '20px' }}>
                <DataTable
                    size="sm"
                    highlightOnHover
                    striped
                    className="rdt_ExpanderRow"
                    columns={columns}
                    data={tableData}
                    responsive="sm"
                    noHeader
                    pagination={false} // Disable pagination
                />
            </Row>
        </Row>
    );
};

export default DropdownComponent;