import React ,{useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import RisingIndia from './pages/RisingIndia';
import Corruption from './pages/Corruption'; 
import InteractiveMap from './pages/Interactive'
import { BrowserRouter,Route,Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
function App() {

const PageTitleAndFavicon = ({ title, faviconUrl }) => {
  useEffect(() => {
    // Change the title dynamically
    document.title = title;

    // Change the favicon dynamically
    const link = document.querySelector("link[rel*='icon']");
    if (link) {
      link.href = faviconUrl;
    }
  }, [title, faviconUrl]); // Update title and favicon when these values change

  return <div></div>;
};

  return (
   
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RisingIndia />}/>
          <Route path="/maps" element={<InteractiveMap/>}/>
          <Route path="/curroption" element={<Corruption/>}></Route>
          
        </Routes>
        <PageTitleAndFavicon title="RisingIndia.fyi" faviconUrl="/images/logo.png" />
      </BrowserRouter>
  );
}

export default App;
