const  perCapitaIncome = [
    { id: "AP", state: "Andhra Pradesh", value: 192587 },
    { id: "AR", state: "Arunachal Pradesh", value: 205645 },
    { id: "AS", state: "Assam", value: 102965 },
    { id: "BR", state: "Bihar", value: 47498 },
    { id: "CT", state: "Chhattisgarh", value: 120704 },
    { id: "GA", state: "Goa", value: 472070 },
    { id: "GJ", state: "Gujarat", value: 241930 },
    { id: "HR", state: "Haryana", value: 264835 },
    { id: "HP", state: "Himachal Pradesh", value: 201271 },
    { id: "JH", state: "Jharkhand", value: 84059 },
    { id: "KA", state: "Karnataka", value: 265623 },
    { id: "KL", state: "Kerala", value: 233855 },
    { id: "MP", state: "Madhya Pradesh", value: 121594 },
    { id: "MH", state: "Maharashtra", value: 215233 },
    { id: "MN", state: "Manipur", value: 91560 },
    { id: "ML", state: "Meghalaya", value: 103335 },
    { id: "MZ", state: "Mizoram", value: 198962 },
    { id: "NL", state: "Nagaland", value: 125887 },
    { id: "OD", state: "Odisha", value: 128181 },
    { id: "PB", state: "Punjab", value: 168705 },
    { id: "RJ", state: "Rajasthan", value: 135962 },
    { id: "SK", state: "Sikkim", value: 463509 },
    { id: "TN", state: "Tamil Nadu", value: 242253 },
    { id: "TS", state: "Telangana", value: 270839 },
    { id: "TR", state: "Tripura", value: 137472 },
    { id: "UK", state: "Uttarakhand", value: 211657 },
    { id: "UP", state: "Uttar Pradesh", value: 73048 },
    { id: "WB", state: "West Bengal", value: 124798 },
    { id: "AN", state: "Andaman & Nicobar Islands", value: 229080 },
    { id: "CH", state: "Chandigarh", value: 333932 },
    { id: "DN", state: "Dadra & Nagar Haveli and Daman & Diu", value: null }, // Data not available
    { id: "DL", state: "Delhi", value: 389529 },
    { id: "JK", state: "Jammu & Kashmir", value: 120790 },
    { id: "LA", state: "Ladakh", value: null }, // Data not available
    { id: "LD", state: "Lakshadweep", value: null }, // Data not available
    { id: "PY", state: "Puducherry", value: 251344 },
  ];

  const gdpInUSD =[
    { id: "AP", state: "Andhra Pradesh", value: 158 },
    { id: "AR", state: "Arunachal Pradesh", value: 5.5 },
    { id: "AS", state: "Assam", value: 58 },
    { id: "BR", state: "Bihar", value: 85 },
    { id: "CT", state: "Chhattisgarh", value: 50 },
    { id: "GA", state: "Goa", value: 11 },
    { id: "GJ", state: "Gujarat", value: 270 },
    { id: "HR", state: "Haryana", value: 120 },
    { id: "HP", state: "Himachal Pradesh", value: 23 },
    { id: "JH", state: "Jharkhand", value: 45 },
    { id: "KA", state: "Karnataka", value: 270 },
    { id: "KL", state: "Kerala", value: 120 },
    { id: "MP", state: "Madhya Pradesh", value: 130 },
    { id: "MH", state: "Maharashtra", value: 420 },
    { id: "MN", state: "Manipur", value: 4.5 },
    { id: "ML", state: "Meghalaya", value: 5.3 },
    { id: "MZ", state: "Mizoram", value: 3.0 },
    { id: "NL", state: "Nagaland", value: 3.8 },
    { id: "OD", state: "Odisha", value: 75 },
    { id: "PB", state: "Punjab", value: 95 },
    { id: "RJ", state: "Rajasthan", value: 140 },
    { id: "SK", state: "Sikkim", value: 2.8 },
    { id: "TN", state: "Tamil Nadu", value: 290 },
    { id: "TS", state: "Telangana", value: 150 },
    { id: "TR", state: "Tripura", value: 7.8 },
    { id: "UK", state: "Uttarakhand", value: 32 },
    { id: "UP", state: "Uttar Pradesh", value: 240 },
    { id: "WB", state: "West Bengal", value: 190 },
    { id: "AN", state: "Andaman & Nicobar Islands", value: 1.0 },
    { id: "CH", state: "Chandigarh", value: 5.3 },
    { id: "DN", state: "Dadra & Nagar Haveli and Daman & Diu", value: 1.5 },
    { id: "DL", state: "Delhi", value: 110 },
    { id: "JK", state: "Jammu & Kashmir", value: 17 },
    { id: "LA", state: "Ladakh", value: 1.1 },
    { id: "LD", state: "Lakshadweep", value: 0.2 },
    { id: "PY", state: "Puducherry", value: 5.4 }
];





export { gdpInUSD, perCapitaIncome };