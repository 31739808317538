// IndiaMap.js
import React, { useState,useEffect } from "react";
import { scaleQuantile } from "d3-scale";
import Map from "../Map";
import {
  COLOR_RANGE,
  INDIA_TOPO_JSON,
  PROJECTION_CONFIG,
  geographyStyle,
} from "./constants/IndiaMapDesignData";



const IndiaMap = (params) => {
  const [indiaMapData, setIndiaMapData] = useState(params?.data);
  const [tooltipContent, setTooltipContent] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);


  const options = indiaMapData.map(({ id, state }) => ({
    value: id,
    label: state,
  }));

  useEffect(() => {
    if (params?.data) {
      setIndiaMapData(params.data);
    }
  }, [params?.data]);

  const colorScale = scaleQuantile()
    .domain(indiaMapData.map((d) => d.value))
    .range(COLOR_RANGE);

  const onMouseEnter = (_geo, current = { value: "NA" }) => {
    return () => {
      setTooltipContent(`${current.state}: ${current.value } ${params?.unit}`);
    };
  };

  const onMouseLeave = () => {
    setTooltipContent("");
  };

  const onSelectState = (geo, state) => {
    setSelectedState(state);
    const selected = options.find((option) => option.value === state.id);
    setSelectedOption(selected);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const state = indiaMapData.find((d) => d.id === value);
    const selected = options.find((option) => option.value === state.id);
    setSelectedOption(selected);
    setSelectedState(state);
  };
 console.log("India maps data",params.data,params.unit)
  return (
    
        <Map
          
          tooltipContent={tooltipContent}
          topologyJson={INDIA_TOPO_JSON}
          data={indiaMapData}
          colorScale={colorScale}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onSelectState={onSelectState}
          geographyStyle={geographyStyle}
          projectionConfig={PROJECTION_CONFIG}
          selectedState={selectedState}
        />
  
 
  );
};

export default IndiaMap;
