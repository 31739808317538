import React from "react";
import Counters from "../components/Counters";
import Header from "../components/Header";
import { Row,Col } from "react-bootstrap";



const Corruption = () => {
  return (
    <Col lg={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} xs={{ span: 12, offset: 0 }}>


    <div className="d-flex flex-column min-vh-100">
      {/* Main content area */}
      <div className="flex-grow-1">
        <div className="container">
          <Header />

          <Row style={{"paddingTop":"25px"}} >
                Website coming Soon
          </Row>
        </div>
      </div>

      <div className="container text-center">
        <hr style={{ "border": "0.5px" }} />
        <p>&copy; RisingIndia.fyi  {new Date().getFullYear()}  | All rights reserved.</p>
      </div>
    </div>

  </Col>
  );
};

export default Corruption;