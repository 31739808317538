import Counter from "./Counter";
import React from "react";
import { Table, Container, Row, Col } from "react-bootstrap";

const Counters = () => {
    return (
        <Container >

            <Container lg={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }} xs={{ span: 12, offset: 0 }}>

                <Counter
                    title="Population of India"
                    baseValue={1428000000} // Estimated population as of 2023
                    rate={0.5} // Approximate growth rate per second, accounting for births minus deaths
                />

                <Counter
                    title="Renewable Energy Capacity (in MW)"
                    baseValue={168000} // India's renewable energy capacity in MW as of 2023
                    rate={0.0025} // Estimated growth rate per second
                />

                <Counter
                    title="Mobile Phone Users in India"
                    baseValue={1180000000} // Estimated mobile phone users in 2023
                    rate={0.4} // Approximate growth rate per second
                />

                <Counter
                    title="Number of Startups in India"
                    baseValue={100000} // Estimated number of startups in India in 2023
                    rate={0.01} // Approximate growth rate per second
                />

                <Counter
                    title="Internet Users in India"
                    baseValue={850000000} // Estimated internet users as of 2023
                    rate={0.15} // Approximate growth rate per second
                />

            </Container>


        </Container>
    );
}

export default Counters;
