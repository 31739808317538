import React, { useState } from 'react';
import { Navbar, Nav, Container, Modal,Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function Header() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


const ContactUsModel = ({ handleClose }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <p>If you have any inquiries, feel free to reach out:</p>
          <ul>
            <li><strong>Email:</strong> realhowkey@gmail.com</li>
            {/* <li><strong>Phone:</strong> +123 456 7890</li> */}
            <li><strong>Address:</strong> Bengaluru, India</li>
          </ul>
          <p>We look forward to hearing from you!</p>
          <center>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          </center>
        </Modal.Body>
        {/* <Modal.Footer>
        
        </Modal.Footer> */}
      </Modal>
    </>
  );
};


  return (
    <Navbar bg="light" expand="lg">
      <Container>

        <div background></div>
        <Navbar.Brand href="/" className="d-flex align-items-center">
          <img
            src="/images/logo.png"
            width="30"
            height="30"
            className="d-inline-block align-top me-2"
            alt="Logo"
          />
          <span> RisingIndia.fyi</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-left">
            <Nav.Link onClick={handleShow}>Contact us</Nav.Link>
            <ContactUsModel handleClose={handleClose}></ContactUsModel>
          </Nav>

          <Nav className="ms-left">
            <Nav.Link href='/maps'>Maps</Nav.Link>
           
          </Nav>

        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default Header;

