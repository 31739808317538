import React, { useState } from "react";
import Counters from "../components/Counters";
import Header from "../components/Header";
import { Row, Col, Dropdown } from "react-bootstrap";
import IndiaMap from "../components/IndiaMap/IndiaMap";

import DropdownComponent from "../components/DropDownWithTable";
import { gdpInUSD,perCapitaIncome } from "../components/IndiaMap/constants/EconomicData";


const optionsList = ["Per Capita Income", "Nominal GDP"];

const InteractiveMap = () => {
  const [selectedOption, setSelectedOption] = useState(optionsList[0]);

  const handleSelect = (value) => {
    setSelectedOption(value);
  };

  const getData =()=>{
    console.log("loaded currectly")
    if(selectedOption==="Per Capita Income"){
      return perCapitaIncome;
    }
    else if(selectedOption==="Nominal GDP"){
      return gdpInUSD;
    }
    // else if(selectedOption==="Population"){
    //   return heatMapData;
    // }
    // else if(selectedOption==="Nominal GDP"){
    //   return heatMapData;
    // }
  }
  const getUnit =()=>{
    if(selectedOption==="Per Capita Income"){
      return "In Rupees";
    }
    else if(selectedOption==="Nominal GDP"){
      return "In billion Doller";
    }
    // else if(selectedOption==="Population"){
    //   return heatMapData;
    // }
    // else if(selectedOption==="Nominal GDP"){
    //   return heatMapData;
    // }
  }

  console.log(selectedOption," <--selected option")


  return (
    <Col lg={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} xs={{ span: 12, offset: 0 }}>
      <div className="d-flex flex-column min-vh-100">
        {/* Main content area */}
        <div className="flex-grow-1">
          <div className="container">
          
            <Header />
            <Row style={{'paddingTop':'20px'}} >

         

            <Col  lg={{ span: 8 }} md={{ span: 8 }} xs={{ span: 12, offset: 0 }} >
            <IndiaMap data={getData()} unit={getUnit()} />
            
            <p style={{textAlign:'right'}}> * Official gov of india data 2024 </p>
            </Col>

            <Col lg={{ span: 4 }} md={{ span: 4 }} xs={{ span: 12, offset: 0 }} className="order-lg-first ">
            <DropdownComponent options={optionsList} handleSelect={handleSelect} selectedOption={selectedOption} tableData={getData()} unit={getUnit()}> </DropdownComponent>
            </Col  >

         
            </Row>
            <div className="container text-center">
              <hr style={{ "border": "0.2px solid"}} />
              <p>&copy; RisingIndia.fyi  {new Date().getFullYear()}  | All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>

    </Col>
  );
};

export default InteractiveMap;
